.bio {
  margin-top: 10px;
  text-align: center;
}

.profilepic {
  width:250px;
  height:330px;
}

.projects {
  padding: 5%;
}

.projcard {
  margin: 30px 30px;
}

.footer-link {
  padding: 20px;
}
.contact-info {
  padding-bottom: 25px;
  font-size: 1.3rem;
  line-height: 2;
  text-align: center;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}

#footer {
  position: relative;
  bottom: 5%;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}